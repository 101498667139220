<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="E-mail" subtitle="Templates" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header header_options">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12 m-top-10 filter-options justify-content-center justify-content-sm-start">
                    <form @submit.prevent="fetch(1)" class="col-12">
                      <div class="form-group mr-auto mb-0" style="width: 70%;margin: 0 auto; margin-bottom: 50px !important;">
                        <div class="input-group">
                          <input
                            class="form-control"
                            :placeholder="`${$t(
                              'generic-str.search-by.default',
                            )}...`"
                            v-model="form.name.lk"
                          />
                          <div class="input-group-prepend">
                            <button type="submit" class="btn icon_btn m-0">
                              <span class="material-symbols-outlined"> search </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row" style="margin-bottom:30px;">
                <div class="col-sm-12 col-md-4 col-lg-6">
                  <h3 class="d-md-none">Templates</h3>
                  <br>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4" style="display: flex;justify-content: flex-end;">
                  <div class="btn-create-template c-pointer" v-modal="{
                      target: 'libary-modal',
                      data: {
                        isLib: false,
                      },
                    }">
                    <span class="template-link-text">{{ $t('sms.templates.lbl-create') }}</span>
                    <span class="material-symbols-outlined icon-circle">add_circle</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-2 opts_card">
                  <!-- Ordenar Por -->
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <div class="order-container-text">
                        <h4 style="color: #697077;">Ordenar por (padrão) </h4>
                        <svg width="24" height="24" class="c-pointer" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M17 20L21 16M3 4H16H3ZM3 8H12H3ZM3 12H12H3ZM17 8V20V8ZM17 20L13 16L17 20Z" stroke="#697077"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </template>
                    <div class="card">
                      <div class="card-header" style="display: flex; align-items: center">
                        {{ $t('generic-str.sort-by') }}
                        <b-dropdown-form class="close-btn">
                          <b-dropdown-item-button @click="closeDropdown"
                            ><span class="material-icons close-icon"> close </span>
                          </b-dropdown-item-button>
                        </b-dropdown-form>
                      </div>
                      <div class="card-body">
                        <ul class="list-unstyled">
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input v-model="form.sort" type="radio" :value="'created_at:desc'" @change="sort()" />
                                <div class="control_indicator"></div>
                                <span>Recente (Padrão)</span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input v-model="form.sort" type="radio" :value="'name'" @change="sort()" />
                                <div class="control_indicator"></div>
                                <span>{{ $t('generic-str.sort-alphabetical') }}</span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input v-model="form.sort" type="radio" :value="'created_at:asc'" @change="sort()" />
                                <div class="control_indicator"></div>
                                <span>Adicionado por último</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-dropdown>
                  <!--/Ordenar Por -->
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row" v-if="fetched">
                <!-- <div
                  v-for="(template, i) in templates"
                  :key="i"
                  class="
                    slate
                    col-sm-4 col-md-4 col-lg-4
                    col-4 col-lg-6 col-xl-2
                    esdev-mb15
                    preview-template-wrapper
                    ng-star-inserted
                  "
                > -->
                <div
                  v-for="(template, i) in templates"
                  :key="i"
                  class="
                    slate
                    col-4 col-lg-6 col-xl-2
                    esdev-mb15
                    preview-template-wrapper
                    ng-star-inserted
                  "
                >
                  <div class="template-content">
                    <div class="one-info">
                      <div class="template-name">
                        {{ template.name }}
                      </div>
                    </div>
                    <!-- <div
                      v-if="template.preview_url"
                      border="0"
                      class="img"
                      :style="`background: url('${template.preview_url}')`"
                    />
                    <div
                      v-else
                      border="0"
                      class="img"
                      :style="`background: url('/assets/img/empty.png')`"
                    /> -->
                    <div class="hover-img">
                      <div class="hover-inner">
                        <template>
                          <iframe
                            :srcdoc="template.body"
                            class="preview-frame"
                            style="transform: scale(0.4); transform-origin: top left; width: 260%;">
                          </iframe>
                          <!-- <img
                            :src="template.preview_url"
                            :alt="template.name" /> -->
                        </template>
                        <!--<template v-else>
                          <img src="@/assets/icons/empty.svg" />
                        </template> -->
                      </div>
                    </div>
                    <div>
                      <router-link
                        :to="`templates/create/${template.id}/false`"
                        class="template-link cursor-pointer"
                        :id="'template-id-' + i"
                      >
                        <span class="template-link-text">Usar template</span>
                        <span class="material-symbols-outlined template-link-icon">keyboard_double_arrow_right</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="responsive-table">
                <div
                  v-if="!templates.length && fetched"
                  class="text-center table-placeholder"
                >
                  <LottieAnimNoData />
                  <h5 class="card-title m-t-20">
                    {{ $t('sms.templates.none') }}
                  </h5>
                  <p>{{ $t('sms.templates.lbl-register') }}</p>
                </div>
                <!-- <div
                  v-if="!fetched"
                  class="qt-block-ui relative"
                  style="padding: 120px; margin: 0"
                /> -->
                <div class="loading min-h-300" v-if="!fetched">
                  <LoadingAnim />
                </div>
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <create-template-modal id="create-template" @submit="fetch(1)" />
    <template-modal id="template-modal" @submit="fetch(1)" />
    <library-modal id="libary-modal" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import CreateTemplateModal from '@/components/mails/CreateTemplateModal.vue';
import TemplateModal from '@/components/mails/TemplateModal.vue';
import MailService from '@/services/mail.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import LibraryModal from '@/components/mails/LibraryModal.vue';
import $ from 'jquery';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    CreateTemplateModal,
    TemplateModal,
    Pagination,
    LibraryModal,
    LottieAnimNoData,
    LoadingAnim,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/mail/reports/stat',
        2: '/mail/templates',
      },

      isSending: false,
      fetched: false,
      templates: [],
      form: {
        page: 1,
        name: {
          lk: '',
        },
        sort: 'created_at:desc',
      },
      pages: 1,
      selectedTemplates: [],
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.templates) {
          return this.selectedTemplates.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedTemplates = selected;
      },
    },
  },
  created() {
    this.fetch(1);
    if ($('#template-id-0').length) {
      console.log('Classes: ', $(this).classList[0]);
    }
  },
  methods: {
    sort() {
      this.fetch(1);
    },
    createPage() {
      this.$router.push('/mail/templates/create');
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;

      MailService.getTemplates(this.form).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data;
          this.pages = response.last_page;
          console.log(response);
          /*
          if ($('#template-id-0').length) {
            console.log('Classes: ', $('#template-id-0').classList[0]);
          }
          */
        },
        (error) => {
          this.$toast.show({
            title: this.$t('app.error'),
            content: error,
            type: 'danger',
          });
        },
      );
    },
    remove(template) {
      Swal.fire({
        title: `${this.$t('template-component.remove-2')} "${template.name}"?`,
        text: this.$t('template-component.warning-2'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          MailService.deleteTemplate(template.id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loaded = true;
              this.fetch(1);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.deleted'),
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    closeDropdown() {
      this.$refs.dropdown.hide();
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('template-component.remove'),
        text: this.$t('template-component.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedTemplates.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          MailService.deleteTemplates({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.deleted'),
                type: 'success',
              });
              this.fetch(1);
              this.isSending = false;
              this.selectedTemplates = [];
            },
            (error) => {
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.preview-frame {
  width: 300px;
  height: 500px;
  border: 1px solid #ddd;
  overflow: hidden;
}
.slate:hover {
  & ~ .slate {
    .hover-inner {
      z-index: 998 !important;
    }
  }
}

.hover-img {
  height: 200px !important;
  cursor: pointer;

  .hover-inner {
    transition: all .2s ease-in;
    height: 100% !important;
    max-height: 500px !important;
    overflow: hidden;
    border-radius: .32rem;
    position: relative;
    z-index: 1000;

    &:hover {
      height: 500px !important;
    }
  }

  img {
    pointer-events: none;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.template-image {
  height: 0;
  padding-bottom: 75%;
  display: inline-block;
  overflow: hidden;
  z-index: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
  margin-bottom: -5px;
  z-index: 0;
}
.one-info {
  background-color: var(--background-3);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
}

.template-content .img {
  height: 200px;
  width: 100%;
  background-position: top !important;
  background-size: cover !important;
  border-radius: 8px;
}
.template-content {
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 30px;
  border: solid 1px #E5E5E5;
  // overflow: hidden;
  padding: 10px;
}
.template-content:hover {
  box-shadow: 0px 3px 36px 6px rgba(107, 35, 220, 0.16);
}
.cursor-pointer {
  cursor: pointer;
}

.template-name {
  margin-top: 0px;
}
.template-name {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

/* Rebranding */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.header_options .input-group > input,
.header_options .input-group button {
  height: 50px;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.card {
  box-shadow: none;
}
.template-link {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  flex-direction: row;

}
.template-link span {
  color: #6B23DC;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.template-link-icon {
  font-style: normal;
  font-weight: 700;
  color: #6B23DC;
  font-size: 15px !important;
  display: inline-block;
  margin-bottom: 1px;
}
::v-deep .order-container-text {
  flex-direction: row;
  display: flex;
  svg {
    margin-left: 5px;
  }
}
.btn-create-template .template-link-text {
  color: #6B23DC;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.btn-create-template {
  display: flex;
  flex-direction: row;
}
.btn-create-template .icon-circle{
  color: #6B23DC;
  margin-left: 5px;
  margin-right: 10px;
}
.rebranding .card .opts_card {
  display: flex;
  align-items: normal !important;
  margin-left: 0px !important;
  gap: 15px;
  justify-content: flex-end !important;
}
.c-pointer {
  cursor: pointer !important;
}

.opts_card {
  &::v-deep .dropdown-menu {
    z-index: 1002;
  }
}
</style>
